// -----------------------------------------------------------------------------
// Common block styles
// -----------------------------------------------------------------------------

/* ------------------------------- wrapper ------------------------------- */

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .wrapper__header {
  }

  .wrapper__main {
    flex: 1 1 auto;
  }

  .wrapper__footer {
  }
}

/* ------------------------------- animation ------------------------------- */

.animation {
  position: relative;

  max-width: 1320px;
  width: 100%;
  height: 650px;

  border-radius: 14px;
  background: $violet;

  overflow: hidden;

  @include respond-to("md") {
    height: 425px;
  }

  .animation__coin-1 {
    position: absolute;
    top: -24px;
    left: -39px;

    @include respond-to("lg") {
      display: none;
    }

    img {
      max-width: 155px;
      width: 100%;
    }
  }

  .animation__coin-1-anim {
    animation: 2s linear forwards coin-1-fall;
    -webkit-animation: 2s linear forwards coin-1-fall;
  }

  @keyframes coin-1-fall {
    0% {
      top: -24px;
    }
    100% {
      top: 100%;
    }
  }

  .animation__coin-2 {
    position: absolute;
    top: 36px;
    left: 165px;

    @include respond-to("lg") {
      display: none;
    }

    img {
      max-width: 145px;
      width: 100%;
    }
  }

  .animation__coin-2-anim {
    animation: 2s linear forwards coin-2-fall;
    -webkit-animation: 2s linear forwards coin-2-fall;
  }

  @keyframes coin-2-fall {
    0% {
      top: 36px;
    }
    100% {
      top: 100%;
    }
  }

  .animation__coin-3 {
    position: absolute;
    top: 180px;
    left: 37px;

    @include respond-to("lg") {
      display: none;
    }

    img {
      max-width: 145px;
      width: 100%;
    }
  }

  .animation__coin-3-anim {
    animation: 2s linear forwards coin-3-fall;
    -webkit-animation: 2s linear forwards coin-3-fall;
  }

  @keyframes coin-3-fall {
    0% {
      top: 180px;
    }
    100% {
      top: 100%;
    }
  }

  .animation__coin-4 {
    position: absolute;
    top: 360px;
    left: 70px;

    @include respond-to("lg") {
      display: none;
    }

    img {
      max-width: 252px;
      width: 100%;
    }
  }

  .animation__coin-4-anim {
    animation: 2s linear forwards coin-4-fall;
    -webkit-animation: 2s linear forwards coin-4-fall;
  }

  @keyframes coin-4-fall {
    0% {
      top: 330px;
    }
    100% {
      top: 100%;
    }
  }

  .animation__coin-5 {
    position: absolute;
    top: 20px;
    right: 60px;

    @include respond-to("lg") {
      display: none;
    }

    img {
      max-width: 252px;
      width: 100%;
    }
  }

  .animation__coin-5-anim {
    animation: 2s linear forwards coin-5-fall;
    -webkit-animation: 2s linear forwards coin-5-fall;
  }

  @keyframes coin-5-fall {
    0% {
      top: 20px;
    }
    100% {
      top: 100%;
    }
  }

  .animation__coin-6 {
    position: absolute;
    top: 330px;
    right: 45px;

    @include respond-to("lg") {
      display: none;
    }

    img {
      max-width: 155px;
      width: 100%;
    }
  }

  .animation__coin-6-anim {
    animation: 2s linear forwards coin-6-fall;
    -webkit-animation: 2s linear forwards coin-6-fall;
  }

  @keyframes coin-6-fall {
    0% {
      top: 330px;
    }
    100% {
      top: 100%;
    }
  }

  .animation__coin-7 {
    position: absolute;
    top: 470px;
    right: 170px;

    @include respond-to("lg") {
      display: none;
    }

    img {
      max-width: 155px;
      width: 100%;
    }
  }

  .animation__coin-7-anim {
    animation: 2s linear forwards coin-7-fall;
    -webkit-animation: 2s linear forwards coin-7-fall;
  }

  @keyframes coin-7-fall {
    0% {
      top: 470px;
    }
    100% {
      top: 100%;
    }
  }

  .animation__coin-8 {
    position: absolute;
    top: 524px;
    right: -38px;

    @include respond-to("lg") {
      display: none;
    }

    img {
      max-width: 155px;
      width: 100%;
    }
  }

  .animation__coin-8-anim {
    animation: 2s linear forwards coin-8-fall;
    -webkit-animation: 2s linear forwards coin-8-fall;
  }

  @keyframes coin-8-fall {
    0% {
      top: 524px;
    }
    100% {
      top: 100%;
    }
  }

  .animation__circle-big {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    max-width: 571px;
    width: 100%;
    margin: auto;

    @include respond-to("md") {
      display: none;
    }
  }

  .animation__big-anim {
    animation: 3s linear 0s normal none infinite running animation__big-anim;
    -webkit-animation: 3s linear 0s normal none infinite running
      animation__big-anim;
    max-width: 571px;
    width: 100%;
  }

  @keyframes animation__big-anim {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  @-webkit-keyframes animation__big-anim {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  .animation__circle-small {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;

    max-width: 376px;
    width: 100%;
    margin: auto;

    @include respond-to("md") {
      top: -20px;
      bottom: initial;

      max-width: 270px;
    }
  }

  .animation__small-anim {
    animation: 3s linear 0s normal none infinite running animation__small-anim;
    -webkit-animation: 3s linear 0s normal none infinite running
      animation__small-anim;
    max-width: 376px;
    width: 100%;

    @include respond-to("md") {
      max-width: 270px;
    }
  }

  @keyframes animation__small-anim {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  @-webkit-keyframes animation__small-anim {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  .animation__circle {
    display: none;

    @include respond-to("md") {
      position: absolute;
      bottom: -9px;
      right: -137px;

      display: block;
      max-width: 376px;
      width: 100%;
    }
  }

  .animation__card {
    position: absolute;
    right: 20px;
    left: 20px;
    bottom: 40px;
    margin: auto;

    max-width: 690px;

    @include respond-to("md") {
      bottom: 20px;
    }
  }
}

.wallet {
  padding: 4px;

  border-radius: 16px;
  background: $grey;

  .wallet__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;

    border-radius: 12px;
    background: $white;
  }

  .wallet__item {
    display: flex;
    padding: 10px 15px 10px 10px;
    align-items: center;
    gap: 10px;

    cursor: pointer;

    &:hover {
      border-radius: 12px;
      background: $grey;
    }
  }

  .wallet__icon {
    width: 32px;
  }

  .wallet__content {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .wallet__title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 102%;
    letter-spacing: -0.64px;
  }

  .wallet__description {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 102%;
    letter-spacing: -0.48px;

    color: rgba($black, 0.3);
  }
}
