/* ------------------------------- faq ------------------------------- */

.faq {
  .faq__container {
    padding-top: 70px;
    padding-bottom: 70px;

    @include respond-to("md") {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }

  .faq__items {
    display: flex;
    flex-flow: column wrap;
    height: 895px;

    @include respond-to("1330px") {
      height: 895px;
    }

    @include respond-to("1235px") {
      height: 915px;
    }

    @include respond-to("1145px") {
      height: 935px;
    }

    @include respond-to("xl") {
      height: 970px;
    }

    @include respond-to("1075px") {
      height: 990px;
    }

    @include respond-to("1055px") {
      height: 1020px;
    }

    @include respond-to("lg") {
      height: 1240px;
    }

    @include respond-to("796px") {
      height: 1265px;
    }

    @include respond-to("md") {
      flex-direction: column;
      height: auto;
    }
  }

  .faq__items::before,
  .faq__items::after {
    content: "";
    flex-basis: 100%;
    width: 0;
    order: 2;
  }

  .faq__item {
    width: 49.5%;
    margin-bottom: 10px;

    @include respond-to("md") {
      width: 100%;
    }
  }

  .faq__item:nth-child(-n + 5) {
    order: 1;
    margin-right: 10px;
  }

  .faq__item:nth-child(n + 6):nth-child(-n + 10) {
    order: 2;
  }

  .faq__row {
    display: flex;
    gap: 10px;
    min-height: 550px;

    @include respond-to("lg") {
      flex-direction: column;
      min-height: initial;
    }
  }

  .faq__col {
    width: 33%;
    height: 100%;

    @include respond-to("lg") {
      width: 100%;
    }
  }
}

/* ------------------------------- main ------------------------------- */

.main {
  background: linear-gradient(
      180deg,
      rgba(#f1f5f933, 0.2) 8.25%,
      rgba(#9fa4a733, 0.2) 100%
    ),
    linear-gradient(0deg, $white 0%, $white 100%), $grey-black;
  border-bottom: 1px solid rgba($black, 0.05);

  .main__container {
    padding-top: 50px;
    padding-bottom: 50px;

    @include respond-to("md") {
      padding-top: 40px;
    }
  }

  .main__header {
    display: flex;
    align-items: center;
    gap: 20px;

    @include respond-to("lg") {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .main__title {
    max-width: 415px;
    width: 100%;

    font-size: 38px;
    font-weight: 500;

    @include respond-to("lg") {
      max-width: 445px;
    }
  }

  .main__description {
  }

  .main__gallery {
    margin-top: 50px;
    margin-bottom: 40px;

    @include respond-to("md") {
      margin-top: 20px;
      margin-bottom: 30px;
    }
  }

  .main__btn {
    position: relative;

    text-align: center;

    button {
      @include respond-to("450px") {
        width: 100%;
      }
    }
  }

  .main__wallet {
    display: none;

    &.active {
      position: absolute;
      top: 62px;
      right: 0;
      left: 0;

      display: block;
      width: 207px;
      margin: auto;

      text-align: left;
    }
  }

  .main__nfts {
    display: flex;
    gap: 20px;
    margin-top: 50px;
    margin-bottom: 40px;

    &::-webkit-scrollbar-thumb {
      display: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    @include respond-to("md") {
      flex-direction: column;
      margin-top: 40px;
      margin-bottom: 40px;
    }

    @include respond-to("xs") {
      flex-direction: row;
      margin-right: -20px;
      margin-left: -20px;

      overflow: auto;
    }
  }

  .main__card-nft {
    max-width: 650px;
    width: 100%;
    max-height: 650px;
    height: 100%;
  }

  @include respond-to("xs") {
    .main__card-nft:first-child {
      margin-left: 20px;
    }

    .main__card-nft:last-child {
      margin-right: 20px;
    }
  }

  .main__animation {
    margin-top: 50px;
    margin-bottom: 20px;

    @include respond-to("md") {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  .main__cards-small {
    display: flex;
    gap: 20px;
    margin-bottom: 50px;

    @include respond-to("1250px") {
      flex-wrap: wrap;
      justify-content: center;
    }

    @include respond-to("md") {
      margin-bottom: 40px;
    }

    @include respond-to("sm") {
      gap: 10px;
    }
  }

  .main__card-small {
    max-width: 315px;
    width: 100%;
    min-height: 345px;

    @include respond-to("md") {
      max-width: initial;
    }
  }

  .main__cards-hide {
    display: flex;
    gap: 40px;
    padding: 40px;

    @include respond-to("lg") {
      flex-direction: column;
      align-items: center;
      padding: 40px 20px;
    }
  }

  .main__card-hide {
    max-width: 386px;
    width: 100%;

    @include respond-to("sm") {
      max-width: none;
    }
  }
}

/* ------------------------------- terms ------------------------------- */

.terms {
  .terms__container {
    padding-top: 50px;
    padding-bottom: 50px;

    @include respond-to("md") {
      padding-top: 40px;
    }
  }

  h1,
  h2,
  p {
    margin-bottom: 15px;
  }

  h2 {
    font-size: 38px;
  }

  p {
    line-height: 22px;
  }
}
