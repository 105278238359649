/* ------------------------------- card-faq ------------------------------- */

.card-faq {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding: 40px;

  background: $grey;
  border-radius: 14px;

  cursor: pointer;
  overflow: hidden;

  .card-faq__content {
  }

  .card-faq__title {
  }

  .card-faq__text {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    color: $white;
  }

  .card-faq__add {
    display: block;
  }

  .card-faq__close {
    display: none;
  }

  //transition: all 0.1s ease-in-out;

  &.open {
    background-color: $black;

    //transition: all 0.1s ease-in-out;

    h3 {
      color: $light-green;
    }

    .card-faq__add {
      display: none;
    }

    .card-faq__close {
      display: block;
    }
  }

  .card-faq__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;

    list-style-type: none;

    li {
      position: relative;

      padding-left: 39px;

      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      color: $white;

      list-style: none;
    }

    li:before {
      position: absolute;
      top: 25%;
      left: 0;
      content: "";

      width: 24px;
      height: 24px;

      background-image: url("/assets/images/icons/check-circle-linear.svg");
      background-size: cover;
    }
  }

  a {
    color: $light-green;

    &:hover {
      text-decoration: underline;
    }
  }
}

/* ------------------------------- card-nft ------------------------------- */

.card-nft {
  position: relative;

  .card-nft__image {
    img {
      max-width: 650px;
      width: 100%;
      max-height: 650px;
      height: 100%;

      border-radius: 14px;
      object-fit: cover;

      @include respond-to("xs") {
        width: 280px;
        height: 280px;
      }
    }
  }

  .card-nft__btn-left {
    position: absolute;
    top: 15px;
    right: 15px;

    width: auto;
    height: 32px;
    padding: 12px 15px;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 102%;
    letter-spacing: -0.56px;

    color: $white;
    background-color: $red;
    border-radius: 8px;

    pointer-events: none;

    @include respond-to("xs") {
      top: 10px;
      right: 10px;

      height: 28px;
      padding: 8px 12px;

      font-size: 12px;
      letter-spacing: -0.48px;
    }
  }

  .card-nft__btn {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    min-width: 165px;
    max-width: 177px;
    width: 100%;
    margin: auto;
    padding: 10px;

    background: $white;
    border-radius: 30px;
    box-shadow: 0 0 30px 0 rgba($black, 0.15);
  }

  .card-nft__remove {
    padding: 0;

    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    img {
      width: 28px;
      height: 28px;
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.2;
    }
  }

  .card-nft__count {
    text-align: center;
  }

  .card-nft__text {
    font-weight: 500;
    line-height: 104%;
    letter-spacing: -0.64px;
  }

  .card-nft__text-small {
    font-size: 12px;
    font-weight: 500;
    line-height: 102%;
    letter-spacing: -0.48px;

    color: rgba($black, 0.3);
  }

  .card-nft__add {
    padding: 0;

    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    img {
      width: 28px;
      height: 28px;
    }

    &:disabled {
      img {
        pointer-events: none;
        opacity: 0.2;
      }
    }
  }

  .card-nft__sold-out {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 40px;

    min-width: 165px;
    max-width: 165px;
    width: 100%;
    height: 50px;
    margin: auto;
    padding: 10px 30px;

    font-weight: 600;

    color: $white;
    background-color: $red;
    border-radius: 30px;

    pointer-events: none;
  }
}

/* ------------------------------- card-congrats ------------------------------- */

.card-congrats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  padding: 20px;

  border-radius: 24px;
  background: $white;

  @include respond-to("md") {
    flex-direction: column;
    gap: 20px;
  }

  .card-congrats__content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @include respond-to("md") {
      align-items: center;
    }
  }

  .card-congrats__title {
    font-weight: 500;
    line-height: 102%;
    letter-spacing: -0.64px;
  }

  .card-congrats__text {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 102%;
    letter-spacing: -0.48px;

    color: rgba($black, 0.6);

    @include respond-to("md") {
      text-align: center;
    }
  }

  .card-congrats__btn-group {
    display: flex;
    gap: 10px;

    &.hidden {
      display: none;
    }
  }

  button {
    height: 44px;
    padding: 15px 30px;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 102%;
    letter-spacing: -0.56px;

    border-radius: 8px;
  }

  .card-congrats__btn-buy {
    min-width: 123px;

    color: $black;
    background-color: $light-green;

    &:hover {
      box-shadow: 0 3px 12px $light-green;
    }
  }

  .card-congrats__btn-logout {
    min-width: 110px;

    color: $white;
    background-color: $red;

    &:hover {
      box-shadow: 0 3px 12px $red;
    }
  }
}

/* ------------------------------- card-small ------------------------------- */

.card-small {
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  border-radius: 14px;
  background: $white;

  &.bg-1 {
    @include respond-to("md") {
      min-height: initial !important;
    }
  }

  &.bg-2 {
    background-image: url("/assets/images/success/rectangle-390.png");
    background-repeat: no-repeat;
    background-position: -5px 45px;
  }

  &.bg-3 {
    @include respond-to("md") {
      min-height: initial !important;
    }
  }

  &.bg-4 {
    background-image: url("/assets/images/success/rectangle-389.png");
    background-repeat: no-repeat;
    background-position: -115px 115px;
  }

  .card-small__title {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 102%;
    letter-spacing: -0.96px;
  }

  .card-small__list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;

    list-style-type: none;

    li {
      display: flex;
      align-items: center;
      gap: 10px;

      color: $grey-semi;

      list-style: none;
    }

    a {
      color: $violet;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

/* ------------------------------- card-hide ------------------------------- */

.card-hide {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @include respond-to("sm") {
    align-items: flex-start;
  }

  .card-hide__title {
    font-weight: 500;
  }

  .card-hide__text {
    text-align: center;

    color: $grey-semi;

    @include respond-to("sm") {
      text-align: left;
    }
  }
}
