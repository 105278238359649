/* ------------------------------- gallery ------------------------------- */

.gallery {
  display: flex;
  gap: 20px;

  @include respond-to("lg") {
    flex-direction: column;
    align-items: center;
  }

  @include respond-to("md") {
    gap: 10px;
  }

  @include respond-to("450px") {
    margin: 0 -20px;
  }

  .gallery__tile-left {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(12, 1fr);
    gap: 20px;
    max-width: 650px;
    width: 100%;
    max-height: 650px;
    height: 100%;

    &::-webkit-scrollbar-thumb {
      display: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    @include respond-to("md") {
      gap: 10px;
    }

    @include respond-to("450px") {
      display: flex;

      overflow: auto;
    }
  }

  .gallery__tile-right {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(12, 1fr);
    gap: 20px;
    max-width: 650px;
    width: 100%;
    max-height: 650px;
    height: 100%;

    &::-webkit-scrollbar-thumb {
      display: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    @include respond-to("md") {
      gap: 10px;
    }

    @include respond-to("450px") {
      display: flex;

      overflow: auto;
    }
  }

  .gallery__l-img-0,
  .gallery__l-img-1,
  .gallery__l-img-2,
  .gallery__l-img-3,
  .gallery__l-img-4,
  .gallery__l-img-5,
  .gallery__r-img-0,
  .gallery__r-img-1,
  .gallery__r-img-2,
  .gallery__r-img-3,
  .gallery__r-img-4,
  .gallery__r-img-5 {
    border-radius: 14px;
    object-fit: cover;
  }

  .gallery__l-img-1,
  .gallery__l-img-2,
  .gallery__l-img-3,
  .gallery__l-img-4,
  .gallery__r-img-2,
  .gallery__r-img-3,
  .gallery__r-img-4,
  .gallery__r-img-5 {
    max-width: 147.5px;
    width: 100%;
    max-height: 147.5px;
    height: 100%;

    @include respond-to("450px") {
      max-width: 150px;
      width: 100%;
      max-height: 150px;
      height: 100%;
    }
  }

  .gallery__l-img-0,
  .gallery__r-img-1 {
    max-width: 315px;
    width: 100%;
    max-height: 315px;
    height: 100%;

    @include respond-to("450px") {
      max-width: 150px;
      width: 100%;
      max-height: 150px;
      height: 100%;
    }
  }

  .gallery__l-img-0 {
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 7;

    @include respond-to("450px") {
      margin-left: 10px;
    }
  }

  .gallery__l-img-1 {
    grid-column-start: 7;
    grid-column-end: 10;
    grid-row-start: 1;
    grid-row-end: 4;
  }

  .gallery__l-img-2 {
    grid-column-start: 10;
    grid-column-end: 13;
    grid-row-start: 1;
    grid-row-end: 4;
  }

  .gallery__l-img-3 {
    grid-column-start: 7;
    grid-column-end: 10;
    grid-row-start: 4;
    grid-row-end: 7;
  }

  .gallery__l-img-4 {
    grid-column-start: 10;
    grid-column-end: 13;
    grid-row-start: 4;
    grid-row-end: 7;
  }

  .gallery__l-img-5 {
    grid-column-start: 1;
    grid-column-end: 13;
    grid-row-start: 7;
    grid-row-end: 13;
    max-width: 650px;
    width: 100%;
    max-height: 315px;
    height: 100%;

    overflow: hidden;

    @include respond-to("450px") {
      margin-right: 10px;

      overflow: initial;
    }

    img {
      width: 650px;
      height: 650px;
      margin: -100px 0;

      @include respond-to("450px") {
        width: 150px;
        height: 150px;
        margin: 0;

        border-radius: 14px;
      }
    }
  }

  .gallery__r-img-0 {
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 13;
    max-width: 315px;
    width: 100%;
    max-height: 650px;
    height: 100%;

    overflow: hidden;

    @include respond-to("450px") {
      margin-left: 10px;

      overflow: initial;
    }

    img {
      width: 650px;
      height: 650px;
      margin-left: -161px;

      @include respond-to("450px") {
        width: 150px;
        height: 150px;
        margin: 0;

        border-radius: 14px;
      }
    }
  }

  .gallery__r-img-1 {
    grid-column-start: 7;
    grid-column-end: 13;
    grid-row-start: 1;
    grid-row-end: 7;
  }

  .gallery__r-img-2 {
    grid-column-start: 7;
    grid-column-end: 10;
    grid-row-start: 7;
    grid-row-end: 10;
  }

  .gallery__r-img-3 {
    grid-column-start: 10;
    grid-column-end: 13;
    grid-row-start: 7;
    grid-row-end: 10;
  }

  .gallery__r-img-4 {
    grid-column-start: 7;
    grid-column-end: 10;
    grid-row-start: 10;
    grid-row-end: 13;
  }

  .gallery__r-img-5 {
    grid-column-start: 10;
    grid-column-end: 13;
    grid-row-start: 10;
    grid-row-end: 13;

    @include respond-to("450px") {
      margin-right: 10px;
    }
  }
}
