// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$text-font-stack: "Work-Sans", "Arial", sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: "Courier New", "DejaVu Sans Mono", "Bitstream Vera Sans Mono",
  "Monaco", monospace !default;

// Colors
$white: #ffffff;
$black: #000000;
$light-green: #b5ff23;
$grey: #f5f5f5;
$grey-black: #eeeeee;
$grey-semi: #5a5a5a;
$red: #ff4646;
$violet: #854bff;

/* ---------------------------------- Base ---------------------------------- */
//$accent-color: $purple;

/* ---------------------------------- Text ---------------------------------- */
$text-main: $black;

/* --------------------------------- Background -------------------------------- */
$bg-color: $white;

/* --------------------------------- Buttons -------------------------------- */
//$button-bg: rgba($accent-color, 0.1);
//$button-text: $accent-color;
//
//$button-active-text: $white;
//$button-active-bg: $accent-color;
//
//$button-disabled-bg: #e6e6e6;
//$button-execute-bg: #e6e6e6;

/* ---------------------------------- Cards --------------------------------- */
//$card-bg: $grey;
//$card-progress-bg: $dark-grey;
//
//$auth-card-bg: $white;

/* --------------------------------- Inputs --------------------------------- */
//$input-bg: $white;
//
//$input-bg-dark: $grey;
//$input-placeholder: rgba($dark, 0.4);

/* ---------------------------------- Utils --------------------------------- */
//$divider-color: rgba($text-main, 0.15);

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels

$breakpoints: (
  "xs": 360px,
  "sm": 480px,
  "md": 768px,
  "lg": 1040px,
  "xl": 1120px,
) !default;
