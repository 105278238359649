// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

/* ------------------------------- button ------------------------------- */

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 102%;
  letter-spacing: -0.8px;

  border: none;
  outline: none;
  cursor: pointer;

  transition: all 0.2s ease-in-out;

  .button__loader {
    position: relative;

    width: 1em;
    height: 1em;
    margin-right: 15px;
    margin-left: 25px;

    color: $light-green;
    border-radius: 50%;

    font-size: 4px;
    text-indent: -9999em;

    animation: mulShdSpin 1.3s infinite linear;
    transform: translateZ(0);
  }

  @keyframes mulShdSpin {
    0%,
    100% {
      box-shadow:
        0 -3em 0 0.2em,
        2em -2em 0 0em,
        3em 0 0 -1em,
        2em 2em 0 -1em,
        0 3em 0 -1em,
        -2em 2em 0 -1em,
        -3em 0 0 -1em,
        -2em -2em 0 0;
    }
    12.5% {
      box-shadow:
        0 -3em 0 0,
        2em -2em 0 0.2em,
        3em 0 0 0,
        2em 2em 0 -1em,
        0 3em 0 -1em,
        -2em 2em 0 -1em,
        -3em 0 0 -1em,
        -2em -2em 0 -1em;
    }
    25% {
      box-shadow:
        0 -3em 0 -0.5em,
        2em -2em 0 0,
        3em 0 0 0.2em,
        2em 2em 0 0,
        0 3em 0 -1em,
        -2em 2em 0 -1em,
        -3em 0 0 -1em,
        -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow:
        0 -3em 0 -1em,
        2em -2em 0 -1em,
        3em 0em 0 0,
        2em 2em 0 0.2em,
        0 3em 0 0em,
        -2em 2em 0 -1em,
        -3em 0em 0 -1em,
        -2em -2em 0 -1em;
    }
    50% {
      box-shadow:
        0 -3em 0 -1em,
        2em -2em 0 -1em,
        3em 0 0 -1em,
        2em 2em 0 0em,
        0 3em 0 0.2em,
        -2em 2em 0 0,
        -3em 0em 0 -1em,
        -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow:
        0 -3em 0 -1em,
        2em -2em 0 -1em,
        3em 0 0 -1em,
        2em 2em 0 -1em,
        0 3em 0 0,
        -2em 2em 0 0.2em,
        -3em 0 0 0,
        -2em -2em 0 -1em;
    }
    75% {
      box-shadow:
        0em -3em 0 -1em,
        2em -2em 0 -1em,
        3em 0em 0 -1em,
        2em 2em 0 -1em,
        0 3em 0 -1em,
        -2em 2em 0 0,
        -3em 0em 0 0.2em,
        -2em -2em 0 0;
    }
    87.5% {
      box-shadow:
        0em -3em 0 0,
        2em -2em 0 -1em,
        3em 0 0 -1em,
        2em 2em 0 -1em,
        0 3em 0 -1em,
        -2em 2em 0 0,
        -3em 0em 0 0,
        -2em -2em 0 0.2em;
    }
  }
}

.button--primary {
  position: relative;

  gap: 10px;
  height: 50px;
  padding: 15px 30px 15px 20px;

  color: $black;
  background-color: $light-green;
  border-radius: 85px;

  &:hover {
    box-shadow: 0 3px 12px $light-green;
  }

  .button__arrow {
    display: none;

    &.active {
      position: absolute;
      bottom: -2px;

      display: block;

      margin: auto;
    }
  }

  .desktop {
    display: block;

    @include respond-to("450px") {
      display: none;
    }
  }

  .mobile {
    display: none;

    @include respond-to("450px") {
      display: block;
    }
  }
}

.button--secondary {
  position: relative;

  height: 60px;
  padding: 20px 30px;

  color: $light-green;
  background-color: $black;
  border-radius: 45px;

  &:hover {
    box-shadow: 0 3px 12px $black;
  }

  &:disabled {
    pointer-events: none;
  }

  .button__arrow {
    display: none;

    &.active {
      position: absolute;
      bottom: -2px;

      display: block;

      margin: auto;
    }
  }

  .desktop {
    display: block;

    @include respond-to("450px") {
      display: none;
    }
  }

  .mobile {
    display: none;

    @include respond-to("450px") {
      display: block;
    }
  }
}

.button--logout {
  gap: 10px;
  height: 50px;
  padding: 15px 20px;

  color: $black;
  background-color: transparent;
  border-radius: 85px;

  @include respond-to("332px") {
    padding: 10px 10px 13px;
  }

  &:hover {
    background-color: $grey;
  }

  .mobile {
    @include respond-to("332px") {
      display: none;
    }
  }
}
