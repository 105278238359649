/* ---------------------------------- Base ---------------------------------- */
/* ---------------------------------- Text ---------------------------------- */
/* --------------------------------- Background -------------------------------- */
/* --------------------------------- Buttons -------------------------------- */
/* ---------------------------------- Cards --------------------------------- */
/* --------------------------------- Inputs --------------------------------- */
/* ---------------------------------- Utils --------------------------------- */
/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  background-color: #ffffff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

img {
  display: block;
}

/* ----------------------------- Work-Sans ----------------------------- */
@font-face {
  font-family: "Work-Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/Work-Sans/WorkSans-Regular.ttf");
}
@font-face {
  font-family: "Work-Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/Work-Sans/WorkSans-Medium.ttf");
}
@font-face {
  font-family: "Work-Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../../assets/fonts/Work-Sans/WorkSans-SemiBold.ttf");
}
@font-face {
  font-family: "Work-Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/Work-Sans/WorkSans-Bold.ttf");
}
/**
 * Basic typography style for text
 */
body {
  color: #000000;
  font: normal 100%/1.2 "Work-Sans", "Arial", sans-serif;
  letter-spacing: normal;
}

button {
  font-family: "Work-Sans", "Arial", sans-serif;
}

.title {
  font-size: 48px;
  font-style: normal;
  line-height: 102%;
  letter-spacing: -1.92px;
}

.subtitle {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.text {
  font-size: 18px;
  font-style: normal;
  line-height: 102%;
  letter-spacing: -0.72px;
}

.container {
  max-width: 1360px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}
@media (max-width: 1120px) {
  .container {
    max-width: 1040px;
  }
}
@media (max-width: 1040px) {
  .container {
    max-width: 768px;
  }
}
@media (max-width: 768px) {
  .container {
    max-width: 480px;
  }
}

/* ------------------------------- button ------------------------------- */
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 102%;
  letter-spacing: -0.8px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.button .button__loader {
  position: relative;
  width: 1em;
  height: 1em;
  margin-right: 15px;
  margin-left: 25px;
  color: #b5ff23;
  border-radius: 50%;
  font-size: 4px;
  text-indent: -9999em;
  animation: mulShdSpin 1.3s infinite linear;
  transform: translateZ(0);
}
@keyframes mulShdSpin {
  0%, 100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

.button--primary {
  position: relative;
  gap: 10px;
  height: 50px;
  padding: 15px 30px 15px 20px;
  color: #000000;
  background-color: #b5ff23;
  border-radius: 85px;
}
.button--primary:hover {
  box-shadow: 0 3px 12px #b5ff23;
}
.button--primary .button__arrow {
  display: none;
}
.button--primary .button__arrow.active {
  position: absolute;
  bottom: -2px;
  display: block;
  margin: auto;
}
.button--primary .desktop {
  display: block;
}
@media (max-width: 450px) {
  .button--primary .desktop {
    display: none;
  }
}
.button--primary .mobile {
  display: none;
}
@media (max-width: 450px) {
  .button--primary .mobile {
    display: block;
  }
}

.button--secondary {
  position: relative;
  height: 60px;
  padding: 20px 30px;
  color: #b5ff23;
  background-color: #000000;
  border-radius: 45px;
}
.button--secondary:hover {
  box-shadow: 0 3px 12px #000000;
}
.button--secondary:disabled {
  pointer-events: none;
}
.button--secondary .button__arrow {
  display: none;
}
.button--secondary .button__arrow.active {
  position: absolute;
  bottom: -2px;
  display: block;
  margin: auto;
}
.button--secondary .desktop {
  display: block;
}
@media (max-width: 450px) {
  .button--secondary .desktop {
    display: none;
  }
}
.button--secondary .mobile {
  display: none;
}
@media (max-width: 450px) {
  .button--secondary .mobile {
    display: block;
  }
}

.button--logout {
  gap: 10px;
  height: 50px;
  padding: 15px 20px;
  color: #000000;
  background-color: transparent;
  border-radius: 85px;
}
@media (max-width: 332px) {
  .button--logout {
    padding: 10px 10px 13px;
  }
}
.button--logout:hover {
  background-color: #f5f5f5;
}
@media (max-width: 332px) {
  .button--logout .mobile {
    display: none;
  }
}

/* ------------------------------- card-faq ------------------------------- */
.card-faq {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding: 40px;
  background: #f5f5f5;
  border-radius: 14px;
  cursor: pointer;
  overflow: hidden;
}
.card-faq .card-faq__text {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #ffffff;
}
.card-faq .card-faq__add {
  display: block;
}
.card-faq .card-faq__close {
  display: none;
}
.card-faq.open {
  background-color: #000000;
}
.card-faq.open h3 {
  color: #b5ff23;
}
.card-faq.open .card-faq__add {
  display: none;
}
.card-faq.open .card-faq__close {
  display: block;
}
.card-faq .card-faq__list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  list-style-type: none;
}
.card-faq .card-faq__list li {
  position: relative;
  padding-left: 39px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #ffffff;
  list-style: none;
}
.card-faq .card-faq__list li:before {
  position: absolute;
  top: 25%;
  left: 0;
  content: "";
  width: 24px;
  height: 24px;
  background-image: url("/assets/images/icons/check-circle-linear.svg");
  background-size: cover;
}
.card-faq a {
  color: #b5ff23;
}
.card-faq a:hover {
  text-decoration: underline;
}

/* ------------------------------- card-nft ------------------------------- */
.card-nft {
  position: relative;
}
.card-nft .card-nft__image img {
  max-width: 650px;
  width: 100%;
  max-height: 650px;
  height: 100%;
  border-radius: 14px;
  object-fit: cover;
}
@media (max-width: 360px) {
  .card-nft .card-nft__image img {
    width: 280px;
    height: 280px;
  }
}
.card-nft .card-nft__btn-left {
  position: absolute;
  top: 15px;
  right: 15px;
  width: auto;
  height: 32px;
  padding: 12px 15px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 102%;
  letter-spacing: -0.56px;
  color: #ffffff;
  background-color: #ff4646;
  border-radius: 8px;
  pointer-events: none;
}
@media (max-width: 360px) {
  .card-nft .card-nft__btn-left {
    top: 10px;
    right: 10px;
    height: 28px;
    padding: 8px 12px;
    font-size: 12px;
    letter-spacing: -0.48px;
  }
}
.card-nft .card-nft__btn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  min-width: 165px;
  max-width: 177px;
  width: 100%;
  margin: auto;
  padding: 10px;
  background: #ffffff;
  border-radius: 30px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
}
.card-nft .card-nft__remove {
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.card-nft .card-nft__remove img {
  width: 28px;
  height: 28px;
}
.card-nft .card-nft__remove:disabled {
  pointer-events: none;
  opacity: 0.2;
}
.card-nft .card-nft__count {
  text-align: center;
}
.card-nft .card-nft__text {
  font-weight: 500;
  line-height: 104%;
  letter-spacing: -0.64px;
}
.card-nft .card-nft__text-small {
  font-size: 12px;
  font-weight: 500;
  line-height: 102%;
  letter-spacing: -0.48px;
  color: rgba(0, 0, 0, 0.3);
}
.card-nft .card-nft__add {
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.card-nft .card-nft__add img {
  width: 28px;
  height: 28px;
}
.card-nft .card-nft__add:disabled img {
  pointer-events: none;
  opacity: 0.2;
}
.card-nft .card-nft__sold-out {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40px;
  min-width: 165px;
  max-width: 165px;
  width: 100%;
  height: 50px;
  margin: auto;
  padding: 10px 30px;
  font-weight: 600;
  color: #ffffff;
  background-color: #ff4646;
  border-radius: 30px;
  pointer-events: none;
}

/* ------------------------------- card-congrats ------------------------------- */
.card-congrats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  padding: 20px;
  border-radius: 24px;
  background: #ffffff;
}
@media (max-width: 768px) {
  .card-congrats {
    flex-direction: column;
    gap: 20px;
  }
}
.card-congrats .card-congrats__content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media (max-width: 768px) {
  .card-congrats .card-congrats__content {
    align-items: center;
  }
}
.card-congrats .card-congrats__title {
  font-weight: 500;
  line-height: 102%;
  letter-spacing: -0.64px;
}
.card-congrats .card-congrats__text {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 102%;
  letter-spacing: -0.48px;
  color: rgba(0, 0, 0, 0.6);
}
@media (max-width: 768px) {
  .card-congrats .card-congrats__text {
    text-align: center;
  }
}
.card-congrats .card-congrats__btn-group {
  display: flex;
  gap: 10px;
}
.card-congrats .card-congrats__btn-group.hidden {
  display: none;
}
.card-congrats button {
  height: 44px;
  padding: 15px 30px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 102%;
  letter-spacing: -0.56px;
  border-radius: 8px;
}
.card-congrats .card-congrats__btn-buy {
  min-width: 123px;
  color: #000000;
  background-color: #b5ff23;
}
.card-congrats .card-congrats__btn-buy:hover {
  box-shadow: 0 3px 12px #b5ff23;
}
.card-congrats .card-congrats__btn-logout {
  min-width: 110px;
  color: #ffffff;
  background-color: #ff4646;
}
.card-congrats .card-congrats__btn-logout:hover {
  box-shadow: 0 3px 12px #ff4646;
}

/* ------------------------------- card-small ------------------------------- */
.card-small {
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 14px;
  background: #ffffff;
}
@media (max-width: 768px) {
  .card-small.bg-1 {
    min-height: initial !important;
  }
}
.card-small.bg-2 {
  background-image: url("/assets/images/success/rectangle-390.png");
  background-repeat: no-repeat;
  background-position: -5px 45px;
}
@media (max-width: 768px) {
  .card-small.bg-3 {
    min-height: initial !important;
  }
}
.card-small.bg-4 {
  background-image: url("/assets/images/success/rectangle-389.png");
  background-repeat: no-repeat;
  background-position: -115px 115px;
}
.card-small .card-small__title {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 102%;
  letter-spacing: -0.96px;
}
.card-small .card-small__list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  list-style-type: none;
}
.card-small .card-small__list li {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #5a5a5a;
  list-style: none;
}
.card-small .card-small__list a {
  color: #854bff;
}
.card-small .card-small__list a:hover {
  text-decoration: underline;
}

/* ------------------------------- card-hide ------------------------------- */
.card-hide {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
@media (max-width: 480px) {
  .card-hide {
    align-items: flex-start;
  }
}
.card-hide .card-hide__title {
  font-weight: 500;
}
.card-hide .card-hide__text {
  text-align: center;
  color: #5a5a5a;
}
@media (max-width: 480px) {
  .card-hide .card-hide__text {
    text-align: left;
  }
}

/* ------------------------------- info-snack-bar ------------------------------- */
/* ------------------------------- error-snack-bar ------------------------------- */
@media (max-width: 600px) {
  .error-snack-bar .mdc-snackbar__surface {
    min-width: auto !important;
    max-width: 420px !important;
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
}
.error-snack-bar .snack-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
.error-snack-bar .snack-bar p, .error-snack-bar .snack-bar a {
  font-family: "Work-Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  color: #ffffff;
}
.error-snack-bar .snack-bar__close {
  cursor: pointer;
}

/* ------------------------------- success-snack-bar ------------------------------- */
.success-snack-bar .snack-bar {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

/* ------------------------------- gallery ------------------------------- */
.gallery {
  display: flex;
  gap: 20px;
}
@media (max-width: 1040px) {
  .gallery {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 768px) {
  .gallery {
    gap: 10px;
  }
}
@media (max-width: 450px) {
  .gallery {
    margin: 0 -20px;
  }
}
.gallery .gallery__tile-left {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  gap: 20px;
  max-width: 650px;
  width: 100%;
  max-height: 650px;
  height: 100%;
}
.gallery .gallery__tile-left::-webkit-scrollbar-thumb {
  display: none;
}
.gallery .gallery__tile-left::-webkit-scrollbar {
  display: none;
}
@media (max-width: 768px) {
  .gallery .gallery__tile-left {
    gap: 10px;
  }
}
@media (max-width: 450px) {
  .gallery .gallery__tile-left {
    display: flex;
    overflow: auto;
  }
}
.gallery .gallery__tile-right {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  gap: 20px;
  max-width: 650px;
  width: 100%;
  max-height: 650px;
  height: 100%;
}
.gallery .gallery__tile-right::-webkit-scrollbar-thumb {
  display: none;
}
.gallery .gallery__tile-right::-webkit-scrollbar {
  display: none;
}
@media (max-width: 768px) {
  .gallery .gallery__tile-right {
    gap: 10px;
  }
}
@media (max-width: 450px) {
  .gallery .gallery__tile-right {
    display: flex;
    overflow: auto;
  }
}
.gallery .gallery__l-img-0,
.gallery .gallery__l-img-1,
.gallery .gallery__l-img-2,
.gallery .gallery__l-img-3,
.gallery .gallery__l-img-4,
.gallery .gallery__l-img-5,
.gallery .gallery__r-img-0,
.gallery .gallery__r-img-1,
.gallery .gallery__r-img-2,
.gallery .gallery__r-img-3,
.gallery .gallery__r-img-4,
.gallery .gallery__r-img-5 {
  border-radius: 14px;
  object-fit: cover;
}
.gallery .gallery__l-img-1,
.gallery .gallery__l-img-2,
.gallery .gallery__l-img-3,
.gallery .gallery__l-img-4,
.gallery .gallery__r-img-2,
.gallery .gallery__r-img-3,
.gallery .gallery__r-img-4,
.gallery .gallery__r-img-5 {
  max-width: 147.5px;
  width: 100%;
  max-height: 147.5px;
  height: 100%;
}
@media (max-width: 450px) {
  .gallery .gallery__l-img-1,
  .gallery .gallery__l-img-2,
  .gallery .gallery__l-img-3,
  .gallery .gallery__l-img-4,
  .gallery .gallery__r-img-2,
  .gallery .gallery__r-img-3,
  .gallery .gallery__r-img-4,
  .gallery .gallery__r-img-5 {
    max-width: 150px;
    width: 100%;
    max-height: 150px;
    height: 100%;
  }
}
.gallery .gallery__l-img-0,
.gallery .gallery__r-img-1 {
  max-width: 315px;
  width: 100%;
  max-height: 315px;
  height: 100%;
}
@media (max-width: 450px) {
  .gallery .gallery__l-img-0,
  .gallery .gallery__r-img-1 {
    max-width: 150px;
    width: 100%;
    max-height: 150px;
    height: 100%;
  }
}
.gallery .gallery__l-img-0 {
  grid-column-start: 1;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 7;
}
@media (max-width: 450px) {
  .gallery .gallery__l-img-0 {
    margin-left: 10px;
  }
}
.gallery .gallery__l-img-1 {
  grid-column-start: 7;
  grid-column-end: 10;
  grid-row-start: 1;
  grid-row-end: 4;
}
.gallery .gallery__l-img-2 {
  grid-column-start: 10;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 4;
}
.gallery .gallery__l-img-3 {
  grid-column-start: 7;
  grid-column-end: 10;
  grid-row-start: 4;
  grid-row-end: 7;
}
.gallery .gallery__l-img-4 {
  grid-column-start: 10;
  grid-column-end: 13;
  grid-row-start: 4;
  grid-row-end: 7;
}
.gallery .gallery__l-img-5 {
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 7;
  grid-row-end: 13;
  max-width: 650px;
  width: 100%;
  max-height: 315px;
  height: 100%;
  overflow: hidden;
}
@media (max-width: 450px) {
  .gallery .gallery__l-img-5 {
    margin-right: 10px;
    overflow: initial;
  }
}
.gallery .gallery__l-img-5 img {
  width: 650px;
  height: 650px;
  margin: -100px 0;
}
@media (max-width: 450px) {
  .gallery .gallery__l-img-5 img {
    width: 150px;
    height: 150px;
    margin: 0;
    border-radius: 14px;
  }
}
.gallery .gallery__r-img-0 {
  grid-column-start: 1;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 13;
  max-width: 315px;
  width: 100%;
  max-height: 650px;
  height: 100%;
  overflow: hidden;
}
@media (max-width: 450px) {
  .gallery .gallery__r-img-0 {
    margin-left: 10px;
    overflow: initial;
  }
}
.gallery .gallery__r-img-0 img {
  width: 650px;
  height: 650px;
  margin-left: -161px;
}
@media (max-width: 450px) {
  .gallery .gallery__r-img-0 img {
    width: 150px;
    height: 150px;
    margin: 0;
    border-radius: 14px;
  }
}
.gallery .gallery__r-img-1 {
  grid-column-start: 7;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 7;
}
.gallery .gallery__r-img-2 {
  grid-column-start: 7;
  grid-column-end: 10;
  grid-row-start: 7;
  grid-row-end: 10;
}
.gallery .gallery__r-img-3 {
  grid-column-start: 10;
  grid-column-end: 13;
  grid-row-start: 7;
  grid-row-end: 10;
}
.gallery .gallery__r-img-4 {
  grid-column-start: 7;
  grid-column-end: 10;
  grid-row-start: 10;
  grid-row-end: 13;
}
.gallery .gallery__r-img-5 {
  grid-column-start: 10;
  grid-column-end: 13;
  grid-row-start: 10;
  grid-row-end: 13;
}
@media (max-width: 450px) {
  .gallery .gallery__r-img-5 {
    margin-right: 10px;
  }
}

/* ------------------------------- faq ------------------------------- */
.faq .faq__container {
  padding-top: 70px;
  padding-bottom: 70px;
}
@media (max-width: 768px) {
  .faq .faq__container {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.faq .faq__items {
  display: flex;
  flex-flow: column wrap;
  height: 895px;
}
@media (max-width: 1330px) {
  .faq .faq__items {
    height: 895px;
  }
}
@media (max-width: 1235px) {
  .faq .faq__items {
    height: 915px;
  }
}
@media (max-width: 1145px) {
  .faq .faq__items {
    height: 935px;
  }
}
@media (max-width: 1120px) {
  .faq .faq__items {
    height: 970px;
  }
}
@media (max-width: 1075px) {
  .faq .faq__items {
    height: 990px;
  }
}
@media (max-width: 1055px) {
  .faq .faq__items {
    height: 1020px;
  }
}
@media (max-width: 1040px) {
  .faq .faq__items {
    height: 1240px;
  }
}
@media (max-width: 796px) {
  .faq .faq__items {
    height: 1265px;
  }
}
@media (max-width: 768px) {
  .faq .faq__items {
    flex-direction: column;
    height: auto;
  }
}
.faq .faq__items::before,
.faq .faq__items::after {
  content: "";
  flex-basis: 100%;
  width: 0;
  order: 2;
}
.faq .faq__item {
  width: 49.5%;
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .faq .faq__item {
    width: 100%;
  }
}
.faq .faq__item:nth-child(-n+5) {
  order: 1;
  margin-right: 10px;
}
.faq .faq__item:nth-child(n+6):nth-child(-n+10) {
  order: 2;
}
.faq .faq__row {
  display: flex;
  gap: 10px;
  min-height: 550px;
}
@media (max-width: 1040px) {
  .faq .faq__row {
    flex-direction: column;
    min-height: initial;
  }
}
.faq .faq__col {
  width: 33%;
  height: 100%;
}
@media (max-width: 1040px) {
  .faq .faq__col {
    width: 100%;
  }
}

/* ------------------------------- main ------------------------------- */
.main {
  background: linear-gradient(180deg, rgba(241, 245, 249, 0.2) 8.25%, rgba(159, 164, 167, 0.2) 100%), linear-gradient(0deg, #ffffff 0%, #ffffff 100%), #eeeeee;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.main .main__container {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (max-width: 768px) {
  .main .main__container {
    padding-top: 40px;
  }
}
.main .main__header {
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 1040px) {
  .main .main__header {
    flex-direction: column;
    align-items: flex-start;
  }
}
.main .main__title {
  max-width: 415px;
  width: 100%;
  font-size: 38px;
  font-weight: 500;
}
@media (max-width: 1040px) {
  .main .main__title {
    max-width: 445px;
  }
}
.main .main__gallery {
  margin-top: 50px;
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .main .main__gallery {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
.main .main__btn {
  position: relative;
  text-align: center;
}
@media (max-width: 450px) {
  .main .main__btn button {
    width: 100%;
  }
}
.main .main__wallet {
  display: none;
}
.main .main__wallet.active {
  position: absolute;
  top: 62px;
  right: 0;
  left: 0;
  display: block;
  width: 207px;
  margin: auto;
  text-align: left;
}
.main .main__nfts {
  display: flex;
  gap: 20px;
  margin-top: 50px;
  margin-bottom: 40px;
}
.main .main__nfts::-webkit-scrollbar-thumb {
  display: none;
}
.main .main__nfts::-webkit-scrollbar {
  display: none;
}
@media (max-width: 768px) {
  .main .main__nfts {
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
@media (max-width: 360px) {
  .main .main__nfts {
    flex-direction: row;
    margin-right: -20px;
    margin-left: -20px;
    overflow: auto;
  }
}
.main .main__card-nft {
  max-width: 650px;
  width: 100%;
  max-height: 650px;
  height: 100%;
}
@media (max-width: 360px) {
  .main .main__card-nft:first-child {
    margin-left: 20px;
  }
  .main .main__card-nft:last-child {
    margin-right: 20px;
  }
}
.main .main__animation {
  margin-top: 50px;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .main .main__animation {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
.main .main__cards-small {
  display: flex;
  gap: 20px;
  margin-bottom: 50px;
}
@media (max-width: 1250px) {
  .main .main__cards-small {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .main .main__cards-small {
    margin-bottom: 40px;
  }
}
@media (max-width: 480px) {
  .main .main__cards-small {
    gap: 10px;
  }
}
.main .main__card-small {
  max-width: 315px;
  width: 100%;
  min-height: 345px;
}
@media (max-width: 768px) {
  .main .main__card-small {
    max-width: initial;
  }
}
.main .main__cards-hide {
  display: flex;
  gap: 40px;
  padding: 40px;
}
@media (max-width: 1040px) {
  .main .main__cards-hide {
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
  }
}
.main .main__card-hide {
  max-width: 386px;
  width: 100%;
}
@media (max-width: 480px) {
  .main .main__card-hide {
    max-width: none;
  }
}

/* ------------------------------- terms ------------------------------- */
.terms .terms__container {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (max-width: 768px) {
  .terms .terms__container {
    padding-top: 40px;
  }
}
.terms h1,
.terms h2,
.terms p {
  margin-bottom: 15px;
}
.terms h2 {
  font-size: 38px;
}
.terms p {
  line-height: 22px;
}

/* ------------------------------- wrapper ------------------------------- */
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.wrapper .wrapper__main {
  flex: 1 1 auto;
}
/* ------------------------------- animation ------------------------------- */
.animation {
  position: relative;
  max-width: 1320px;
  width: 100%;
  height: 650px;
  border-radius: 14px;
  background: #854bff;
  overflow: hidden;
}
@media (max-width: 768px) {
  .animation {
    height: 425px;
  }
}
.animation .animation__coin-1 {
  position: absolute;
  top: -24px;
  left: -39px;
}
@media (max-width: 1040px) {
  .animation .animation__coin-1 {
    display: none;
  }
}
.animation .animation__coin-1 img {
  max-width: 155px;
  width: 100%;
}
.animation .animation__coin-1-anim {
  animation: 2s linear forwards coin-1-fall;
  -webkit-animation: 2s linear forwards coin-1-fall;
}
@keyframes coin-1-fall {
  0% {
    top: -24px;
  }
  100% {
    top: 100%;
  }
}
.animation .animation__coin-2 {
  position: absolute;
  top: 36px;
  left: 165px;
}
@media (max-width: 1040px) {
  .animation .animation__coin-2 {
    display: none;
  }
}
.animation .animation__coin-2 img {
  max-width: 145px;
  width: 100%;
}
.animation .animation__coin-2-anim {
  animation: 2s linear forwards coin-2-fall;
  -webkit-animation: 2s linear forwards coin-2-fall;
}
@keyframes coin-2-fall {
  0% {
    top: 36px;
  }
  100% {
    top: 100%;
  }
}
.animation .animation__coin-3 {
  position: absolute;
  top: 180px;
  left: 37px;
}
@media (max-width: 1040px) {
  .animation .animation__coin-3 {
    display: none;
  }
}
.animation .animation__coin-3 img {
  max-width: 145px;
  width: 100%;
}
.animation .animation__coin-3-anim {
  animation: 2s linear forwards coin-3-fall;
  -webkit-animation: 2s linear forwards coin-3-fall;
}
@keyframes coin-3-fall {
  0% {
    top: 180px;
  }
  100% {
    top: 100%;
  }
}
.animation .animation__coin-4 {
  position: absolute;
  top: 360px;
  left: 70px;
}
@media (max-width: 1040px) {
  .animation .animation__coin-4 {
    display: none;
  }
}
.animation .animation__coin-4 img {
  max-width: 252px;
  width: 100%;
}
.animation .animation__coin-4-anim {
  animation: 2s linear forwards coin-4-fall;
  -webkit-animation: 2s linear forwards coin-4-fall;
}
@keyframes coin-4-fall {
  0% {
    top: 330px;
  }
  100% {
    top: 100%;
  }
}
.animation .animation__coin-5 {
  position: absolute;
  top: 20px;
  right: 60px;
}
@media (max-width: 1040px) {
  .animation .animation__coin-5 {
    display: none;
  }
}
.animation .animation__coin-5 img {
  max-width: 252px;
  width: 100%;
}
.animation .animation__coin-5-anim {
  animation: 2s linear forwards coin-5-fall;
  -webkit-animation: 2s linear forwards coin-5-fall;
}
@keyframes coin-5-fall {
  0% {
    top: 20px;
  }
  100% {
    top: 100%;
  }
}
.animation .animation__coin-6 {
  position: absolute;
  top: 330px;
  right: 45px;
}
@media (max-width: 1040px) {
  .animation .animation__coin-6 {
    display: none;
  }
}
.animation .animation__coin-6 img {
  max-width: 155px;
  width: 100%;
}
.animation .animation__coin-6-anim {
  animation: 2s linear forwards coin-6-fall;
  -webkit-animation: 2s linear forwards coin-6-fall;
}
@keyframes coin-6-fall {
  0% {
    top: 330px;
  }
  100% {
    top: 100%;
  }
}
.animation .animation__coin-7 {
  position: absolute;
  top: 470px;
  right: 170px;
}
@media (max-width: 1040px) {
  .animation .animation__coin-7 {
    display: none;
  }
}
.animation .animation__coin-7 img {
  max-width: 155px;
  width: 100%;
}
.animation .animation__coin-7-anim {
  animation: 2s linear forwards coin-7-fall;
  -webkit-animation: 2s linear forwards coin-7-fall;
}
@keyframes coin-7-fall {
  0% {
    top: 470px;
  }
  100% {
    top: 100%;
  }
}
.animation .animation__coin-8 {
  position: absolute;
  top: 524px;
  right: -38px;
}
@media (max-width: 1040px) {
  .animation .animation__coin-8 {
    display: none;
  }
}
.animation .animation__coin-8 img {
  max-width: 155px;
  width: 100%;
}
.animation .animation__coin-8-anim {
  animation: 2s linear forwards coin-8-fall;
  -webkit-animation: 2s linear forwards coin-8-fall;
}
@keyframes coin-8-fall {
  0% {
    top: 524px;
  }
  100% {
    top: 100%;
  }
}
.animation .animation__circle-big {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 571px;
  width: 100%;
  margin: auto;
}
@media (max-width: 768px) {
  .animation .animation__circle-big {
    display: none;
  }
}
.animation .animation__big-anim {
  animation: 3s linear 0s normal none infinite running animation__big-anim;
  -webkit-animation: 3s linear 0s normal none infinite running animation__big-anim;
  max-width: 571px;
  width: 100%;
}
@keyframes animation__big-anim {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-webkit-keyframes animation__big-anim {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.animation .animation__circle-small {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  max-width: 376px;
  width: 100%;
  margin: auto;
}
@media (max-width: 768px) {
  .animation .animation__circle-small {
    top: -20px;
    bottom: initial;
    max-width: 270px;
  }
}
.animation .animation__small-anim {
  animation: 3s linear 0s normal none infinite running animation__small-anim;
  -webkit-animation: 3s linear 0s normal none infinite running animation__small-anim;
  max-width: 376px;
  width: 100%;
}
@media (max-width: 768px) {
  .animation .animation__small-anim {
    max-width: 270px;
  }
}
@keyframes animation__small-anim {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-webkit-keyframes animation__small-anim {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.animation .animation__circle {
  display: none;
}
@media (max-width: 768px) {
  .animation .animation__circle {
    position: absolute;
    bottom: -9px;
    right: -137px;
    display: block;
    max-width: 376px;
    width: 100%;
  }
}
.animation .animation__card {
  position: absolute;
  right: 20px;
  left: 20px;
  bottom: 40px;
  margin: auto;
  max-width: 690px;
}
@media (max-width: 768px) {
  .animation .animation__card {
    bottom: 20px;
  }
}

.wallet {
  padding: 4px;
  border-radius: 16px;
  background: #f5f5f5;
}
.wallet .wallet__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  border-radius: 12px;
  background: #ffffff;
}
.wallet .wallet__item {
  display: flex;
  padding: 10px 15px 10px 10px;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.wallet .wallet__item:hover {
  border-radius: 12px;
  background: #f5f5f5;
}
.wallet .wallet__icon {
  width: 32px;
}
.wallet .wallet__content {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.wallet .wallet__title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 102%;
  letter-spacing: -0.64px;
}
.wallet .wallet__description {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 102%;
  letter-spacing: -0.48px;
  color: rgba(0, 0, 0, 0.3);
}

/* ------------------------------- header ------------------------------- */
.header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.header .header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding-top: 18px;
  padding-bottom: 17px;
}
.header .header__address {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 102%;
  letter-spacing: -0.8px;
  color: rgba(0, 0, 0, 0.2);
}
@media (max-width: 768px) {
  .header .header__address {
    display: none;
  }
}
.header .header__btn {
  position: relative;
}
.header .header__wallet {
  display: none;
}
.header .header__wallet.active {
  position: absolute;
  top: 52px;
  right: 0;
  display: block;
  width: 207px;
}
/* ------------------------------- footer ------------------------------- */
.footer {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #000000;
}
@media (max-width: 768px) {
  .footer {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.footer .footer__container {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 20px;
}
@media (max-width: 768px) {
  .footer .footer__container {
    grid-template-columns: none;
    grid-template-rows: auto;
    justify-items: center;
  }
}
.footer .footer__copy,
.footer .footer__link {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #ffffff;
}
.footer .footer__copy {
  text-align: center;
}
.footer .footer__link {
  text-decoration-line: underline;
  transition: all 0.2s ease-in-out;
}
.footer .footer__link:hover {
  color: #b5ff23;
}